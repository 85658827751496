body {
  font-family: sans-serif;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: none;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  overflow-y: auto;
  padding-left: 0;
  width: 80vw;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #a8bdc8;
  color: black;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}