.whereWhenPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.whereWhenPage img {
  height: 40vmin;
  float: right;
  margin-left: auto;
  margin-right: 2vw;
}

.map {
  width: 80vw;
  padding-bottom: 1em;
  height: 80vh;
}