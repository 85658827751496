body {
  background-color: white;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #151515;
  overflow-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  color: rgb(33, 101, 169);
  text-decoration: underline;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-style: italic;
  font-size: 300%;
}

h2 {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 150%;
  font-weight: bold;
  padding-bottom: 0.25em;
  padding-top: 1em;
}

h3 {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  font-weight: bold;
  padding-bottom: 0.25em;
  padding-top: 1em;
  text-decoration: underline;
}

hr {
  margin: 3em 0;
  border: 1px solid grey;
  border-radius: 1em;
  background-color: gray;
  border-width: 1px;
  width: 80vw;
}

button {
  background-color: white;
  color: black;
  margin-top: 1em;
  border-radius: 25px;
  border-style: solid;
  border-width: 2px;
  border-color: #aaaaaa;
  padding:0.25em
}

button:active {
  background-color: #eee;
  border-color: #555;
}

input {
  text-align: right;
  vertical-align: middle;
}

input[type=checkbox] {
  transform: scale(1.5);
}

input[type=text] {
  width: auto;
  font-size: 80%;
  text-align: left;
  border-width: 2px;
  padding: 4px;
  border: 1px solid #999;
  padding: 0.5rem;
}
