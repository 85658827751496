.detailsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.faq {
  width: 75%;
  padding-top: 10vh;
  padding-bottom: 1em;
}

.faq h2,
.faq h3 {
  margin-top: calc(5vh + 1em);
}

.faq hr {
  margin: 1em 0;
}

.faq p {
  font-size: smaller;
}

.faq button {
  margin-top: 0;
}

.faq img {
  height: 30vmin;
}

.faq>textarea {
  width: 100%;
}

.submitArea {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.submitArea input {
  margin-right: 0.5em;
  margin-top: -0.25em;
  margin-bottom: auto;
}

.submitArea label {
  margin-top: 0.25em;
  margin-bottom: auto;
  font-size: 60%;
  color: #555;
}

.submitArea label:hover {
  color: black;
}

.message {
  font-size: 50%;
  text-align: left;
  padding-right: 45%;
  padding-left: 10%;
}

.private {
  color: blue;
}

.response {
  font-size: 50%;
  text-align: right;
  color: gray;
  padding-left: 45%;
  padding-right: 10%;
}

.message::before,
.message::after {
  content: '"';
}