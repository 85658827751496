.hero {
  text-align: center;
}

.hero-header {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  font-size: calc(10px + 2vmin);
  justify-content: space-around;
}

.hero a {
  color: #61bbfb;
}

.hero-header img {
  height: 50vh;
  padding-bottom: 5vh;
}